 /*
 * App Style
 *
 * The base style for the application.
 *
 */
 @charset "utf-8/index";

 @import '_variables.scss';

@font-face {
  font-family: 'Canela Web';
  src: url('../fonts/Canela-Thin-Web.woff2') format('woff2'),
       url('../fonts/Canela-Thin-Web.woff') format('woff');
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
}

@import url('https://fonts.googleapis.com/css?family=Spectral:300,400,500,600&display=swap');

@keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

::selection {
  background: #DB008B; /* WebKit/Blink Browsers */
}

.intro-container {
  margin-left: 80px;
  max-width: 900px;
}

h1 {
  font-family: 'Canela Web';
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
  font-size: 4rem;
  color: $tiffany-darkest;
  line-height: 4rem;
  letter-spacing: .69px;
}

h3 {
  font-family: 'Canela Web';
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
  font-size: 2.5rem;
  color: $tiffany-darkest;
}

p { 
  font-family: 'Spectral';
  color: $tiffany-darker;
  font-size: 1rem;
  margin-block-start: 0;
  margin-block-end: 0;
}

pre {
  font-family: inherit;
  margin: inherit
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

  
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.notouch {
  pointer-events: none;
}

  /* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 812px) { 
  h1 {
    font-size: 400%;
  }
  
  h3 {
    font-size: 2.5rem;
    margin: 25px 50px;
  }
  
    p { 
      margin-block-start: 0;
      margin-block-end: 0;
      font-family: Spectral;
      color: $tiffany-darker;
      font-size: 16px;
    }
  
    pre {
      font-family: inherit;
      margin: inherit
    }  
}