  /*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */
 
 $primary-start: #ffffff;
 $primary-stop: #f0f0f0;
 
 $secondary-start: #DB0000;
 $secondary-stop: #81D8D0;

 $tiffany: #81D8D0;
 $tiffany-lighter: #E0FFFC;
 $tiffany-light: #C8FFFA;
 $tiffany-dark: #53D4C8;
 $tiffany-darker: #009A8C;
 $tiffany-darkest: #006057;
 



 // the :export directive is the magic sauce for webpack
:export {
  p1: $tiffany-dark;
  p2: $tiffany-darker;
  p3: $tiffany-darkest;
  p4: $secondary-start;
  tiffany: $tiffany;
  tiffanyLight: $tiffany-light;
  tiffanyDark: $tiffany-dark;
  tiffanyDarker: $tiffany-darker;
  tiffanyDarkest: $tiffany-darkest;
}